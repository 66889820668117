<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div class="text-h4">Transportadoras</div>
  </v-container>
</template>

<script>
export default {
  name: "RecordsCouriers",
  metaInfo() {
    return {
      title: "Transportadoras",
    };
  },
};
</script>
